import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import _ from 'lodash'

export default class extends Controller {
  static targets = [
    'message',
    'rateBody',
    'rateHeadline',
    'previewReview',
    'goodReviewBody',
    'goodReviewHeadline',
    'previewReviewThanks',
    'badReviewBody',
    'badReviewHeadline',
    'previewFeedbacks',
    'facebookEnabled',
    'facebookUrl',
    'facebookUrlWrapper',
    'googleEnabled',
    'googleUrl',
    'googleUrlWrapper',
    'tripadvisorEnabled',
    'tripadvisorUrl',
    'tripadvisorUrlWrapper',
    'listingUrlEnabled',
    'goodReviewThresholdInput',
    'goodReviewThreshold',
    'form',
    'daysSinceWifiConnect',
    'previewRentalProperty',
    'previewQRCode',
    'qrCodePlaceholder',
    'qrCodeSpinner'
  ]

  onFacebookEnabledToggle() {
    const facebookUrlWrapperTarget = $(this.facebookUrlWrapperTarget)

    if (this.facebookEnabledTarget.checked) {
      facebookUrlWrapperTarget.removeClass('hidden')
    } else {
      facebookUrlWrapperTarget.addClass('hidden')
      $(this.facebookUrlTarget).val('')
    }

    this.refreshReviewThanksPreview()
  }

  onGoogleEnabledToggle() {
    const googleUrlWrapperTarget = $(this.googleUrlWrapperTarget)

    if (this.googleEnabledTarget.checked) {
      googleUrlWrapperTarget.removeClass('hidden')
    } else {
      googleUrlWrapperTarget.addClass('hidden')
      $(this.googleUrlTarget).val('')
    }

    this.refreshReviewThanksPreview()
  }

  onTripadvisorEnabledToggle() {
    const tripadvisorUrlWrapperTarget = $(this.tripadvisorUrlWrapperTarget)

    if (this.tripadvisorEnabledTarget.checked) {
      tripadvisorUrlWrapperTarget.removeClass('hidden')
    } else {
      tripadvisorUrlWrapperTarget.addClass('hidden')
      $(this.tripadvisorUrlTarget).val('')
    }

    this.refreshReviewThanksPreview()
  }

  onListingUrlEnabledToggle() {
    this.refreshReviewThanksPreview()
  }

  onGoodReviewThresholdChange() {
    $(this.goodReviewThresholdTargets).text($(this.goodReviewThresholdInputTarget).val())
  }

  connect() {
    this.initialize()

    this.onFacebookEnabledToggle()
    this.onGoogleEnabledToggle()
    this.onTripadvisorEnabledToggle()
    this.onListingUrlEnabledToggle()
    this.onGoodReviewThresholdChange()

    this.refreshReviewPreview()
    this.refreshReviewThanksPreview()
    this.refreshFeedbacksPreview()
    this.refreshQRCode()
  }

  onRentalPropertyChange(e) {
    $(this.previewRentalPropertyTargets).val(e.target.value)

    this.refreshReviewPreview()
    this.refreshReviewThanksPreview()
    this.refreshFeedbacksPreview()
    this.refreshQRCode()
  }

  onReviewChange() {
    this.debouncedRefreshReviewPreview()
  }

  onReviewThanksChange() {
    this.debouncedRefreshReviewThanksPreview()
  }

  onFeedbackChange() {
    this.debouncedRefreshFeedbacksPreview()
  }

  initialize() {
    this.debouncedRefreshReviewPreview = _.debounce(() => {
      this.refreshReviewPreview()
    }, 350)

    this.debouncedRefreshReviewThanksPreview = _.debounce(() => {
      this.refreshReviewThanksPreview()
    }, 350)

    this.debouncedRefreshFeedbacksPreview = _.debounce(() => {
      this.refreshFeedbacksPreview()
    }, 350)
  }

  refreshReviewPreview() {
    const src = `${this.data.get('preview-review-path')}?${$.param(this.previewReviewParams)}`
    $(this.previewReviewTarget).attr('src', src)
  }

  refreshReviewThanksPreview() {
    const src = `${this.data.get('preview-review-thanks-path')}?${$.param(this.previewReviewThanksParams)}`
    $(this.previewReviewThanksTarget).attr('src', src)
  }

  refreshFeedbacksPreview() {
    const src = `${this.data.get('preview-feedbacks-path')}?${$.param(this.previewFeedbacksParams)}`
    $(this.previewFeedbacksTarget).attr('src', src)
  }

  refreshQRCode() {
    $(this.qrCodePlaceholderTarget).removeClass('hidden')
    $(this.previewQRCodeTarget).addClass('hidden')
    $(this.qrCodeSpinnerTarget).addClass('hidden')
  }

  onGenerateQRCode(e) {
    e.preventDefault()

    const buttonElement = e.currentTarget

    Rails.disableElement(buttonElement)
    $(this.qrCodePlaceholderTarget).addClass('hidden')
    $(this.previewQRCodeTarget).addClass('hidden')
    $(this.qrCodeSpinnerTarget).removeClass('hidden')

    $.post(this.data.get('preview-campaign-url'), this.previewCampaignParams).done((response) => {
      Rails.enableElement(buttonElement)

      if (response.saved) {
        $(this.qrCodeSpinnerTarget).addClass('hidden')
        $(this.previewQRCodeTarget).removeClass('hidden')
        $(this.previewQRCodeTarget).html(response.preview_qr_code)
      } else {
        this.refresh(response.form_html)
      }

    })
  }

  refresh(formHtml) {
    $(this.formTarget).html(formHtml)

    this.connect()
  }

  get previewReviewParams() {
    return {
      rental_property_id: this.hasPreviewRentalPropertyTarget && this.previewRentalPropertyTarget.value,
      rate_headline: this.rateHeadlineTarget.value,
      rate_body: this.rateBodyTarget.value
    }
  }

  get previewReviewThanksParams() {
    return {
      rental_property_id: this.hasPreviewRentalPropertyTarget && this.previewRentalPropertyTarget.value,
      good_review_headline: this.goodReviewHeadlineTarget.value,
      good_review_body: this.goodReviewBodyTarget.value,
      facebook_url: this.facebookEnabledTarget.checked ? (this.facebookUrlTarget.value || 'https://www.stayfi.com') : null,
      google_url: this.googleEnabledTarget.checked ? (this.googleUrlTarget.value || 'https://www.stayfi.com') : null,
      tripadvisor_url: this.tripadvisorEnabledTarget.checked ? (this.tripadvisorUrlTarget.value || 'https://www.stayfi.com') : null,
      listing_url_enabled: this.listingUrlEnabledTarget.checked
    }
  }

  get previewFeedbacksParams() {
    return {
      rental_property_id: this.hasPreviewRentalPropertyTarget && this.previewRentalPropertyTarget.value,
      bad_review_headline: this.badReviewHeadlineTarget.value,
      bad_review_body: this.badReviewBodyTarget.value
    }
  }

  get previewCampaignParams() {
    return {
      model: {
        days_since_wifi_connect: $(this.daysSinceWifiConnectTarget).val(),
        message: $(this.messageTarget).val(),
        rate_headline: $(this.rateHeadlineTarget).val(),
        rate_body: $(this.rateBodyTarget).val(),
        good_review_threshold: $(this.goodReviewThresholdInputTarget).val(),
        good_review_body: $(this.goodReviewBodyTarget).val(),
        good_review_headline: $(this.goodReviewHeadlineTarget).val(),
        bad_review_body: $(this.badReviewBodyTarget).val(),
        bad_review_headline: $(this.badReviewHeadlineTarget).val(),
        facebook_url: $(this.facebookUrlTarget).val(),
        facebook_url_enabled: $(this.facebookUrlEnabledTarget).val(),
        google_url: $(this.googleUrlTarget).val(),
        google_url_enabled: $(this.googleUrlEnabledTarget).val(),
        tripadvisor_url: $(this.tripadvisorUrlTarget).val(),
        tripadvisor_url_enabled: $(this.tripadvisorUrlEnabledTarget).val(),
        listing_url_enabled: $(this.listingUrlEnabledTarget).val(),
        preview_rental_property_id: $(this.previewRentalPropertyTarget).val()
      }
    }
  }
}
