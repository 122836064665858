import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'takeover',
    'container'
  ]

  show(e) {
    e.preventDefault()

    const extraClasses = $(e.currentTarget).data('extra-classes')
    const skipReload = $(e.currentTarget).data('skip-reload')

    if (extraClasses) {
      $(this.containerTarget).addClass(extraClasses)
    }

    $(this.takeoverTarget).addClass('shown')

    if (skipReload === true && this.takeoverTarget.dataset.loaded === 'true') {
      return
    }

    const url = (e.detail || {}).url || $(e.currentTarget).data('url')
    this.containerTarget.innerHTML = `
      <div data-controller="content-loader" data-content-loader-url="${url}" class="h-full">
        <div data-target="content-loader.spinner" class="px-4 py-5 sm:p-6 flex h-full items-center justify-center">
          <div class="spinner"></div>
        </div>
        <div data-target="content-loader.contentContainer"></div>
      </div>`
  
    this.takeoverTarget.dataset.loaded = 'true'
  }

  hide(e) {
    e.preventDefault()
    $(this.takeoverTarget).removeClass('shown')

    const hideTakeoverHandler = (e) => {
      if (e.propertyName === 'top') {
        this.takeoverTarget.removeEventListener('webkitTransitionEnd', hideTakeoverHandler, false)
        document.dispatchEvent(new CustomEvent('takeover_hide_completed'))
      }
    }

    this.takeoverTarget.addEventListener('webkitTransitionEnd', hideTakeoverHandler, false)
  }

  scrollToTop() {
    this.containerTarget.scrollTo(0, 0)
  }
}
