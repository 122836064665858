import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'modal', 'background', 'container' ]
  static AVAILABLE_BG_CLASSES = ['bg-white', 'bg-blue-100']

  connect() {
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('modal_controller_loaded'))
    }, 0)

    document.addEventListener('close_modal', this.close.bind(this))
  }

  show(e) {
    e.preventDefault()

    const customClassWidth = $(e.currentTarget).data('custom-class-width') || e.detail.customClassWidth

    if (customClassWidth) {
      $(this.containerTarget).removeClass('sm:w-full');
      $(this.containerTarget).addClass(customClassWidth);
    }

    $(this.containerTarget).removeClass(this.constructor.AVAILABLE_BG_CLASSES).addClass(this.bgColorFor(e.detail));

    $(this.modalTarget).addClass('sm:flex').removeClass('hidden')
    $(this.backgroundTarget).addClass('ease-out duration-300 opacity-100').removeClass('ease-in duration-200 opacity-0')

    $.get((e.detail || {}).url || $(e.currentTarget).data('url')).done(data => {
      $(this.containerTarget).addClass('ease-out duration-300 opacity-100 opacity-100 translate-y-0 sm:scale-100').removeClass('ease-in duration-200 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95')
      $(this.containerTarget).html(data)
    })
  }

  showTurbo(e) {
    e.preventDefault()

    if ($(e.currentTarget).data('custom-class-width')) {
      $(this.containerTarget).removeClass('sm:w-full');
      $(this.containerTarget).addClass($(e.currentTarget).data('custom-class-width'));
    }

    $(this.modalTarget).addClass('sm:flex').removeClass('hidden')
    $(this.backgroundTarget).addClass('ease-out duration-300 opacity-100').removeClass('ease-in duration-200 opacity-0')

    const url = (e.detail || {}).url || $(e.currentTarget).data('url');

    fetch(url, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    })
      .then(response => response.text())
      .then(data => {
        $(this.containerTarget).addClass('ease-out duration-300 opacity-100 opacity-100 translate-y-0 sm:scale-100').removeClass('ease-in duration-200 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95')
        $(this.containerTarget).html(data)
      })
  }

  close(e) {
    e.preventDefault()

    $(this.modalTarget).addClass('hidden').removeClass('sm:flex')
    $(this.backgroundTarget).addClass('ease-in duration-200 opacity-0').removeClass('ease-out duration-300 opacity-100')
    $(this.containerTarget).addClass('ease-in duration-200 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95').removeClass('ease-out duration-300 opacity-100 opacity-100 translate-y-0 sm:scale-100')

    document.dispatchEvent(new CustomEvent('modal_closed', { detail: { targetId: e.target.id }}))
  }

  bgColorFor(detail) {
    return this.constructor.AVAILABLE_BG_CLASSES.includes(detail.customBgColor) ? detail.customBgColor : 'bg-white'
  }
}
