import { Controller } from 'stimulus'
import { eventTracker } from '../../utilities/customer'

export default class extends Controller {
  trackEvent() {
    const flow = this.data.get('flow')
    const action = this.data.get('action')
    const object = this.data.get('object')
    const initiatedFrom = this.data.get('initiatedFrom')
    const name = this.data.get('name')
    const options = {
      action,
      object,
      flow
    }
    if (initiatedFrom) {
      options.initiatedFrom = initiatedFrom
    }

    eventTracker.track(name, options)
  }
}
