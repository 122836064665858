const THUMB_WIDTH = 26

/**
 * Formats a number with commas as thousand separators
 * @param {number} number - The number to format
 * @returns {string} The formatted number string with commas
 * @example
 * numberWithCommas("1234.56") // returns 1,234.56
 * numberWithCommas("123.45") // returns 123.45
 */
export const numberWithCommas = number => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const sliderStyles = ({ sliderPosition, min, max, unitOffsetWidth }) => {
  const percent = (sliderPosition - min) * 100 / (max - min)
  const fixedOffset = unitOffsetWidth / 2 - THUMB_WIDTH / 2
  const thumbOffset = THUMB_WIDTH / 100 * percent
  const dynamicOffset = fixedOffset + thumbOffset
  const backgroundOffset = THUMB_WIDTH / 2 - thumbOffset

  const sliderBackgroundSize = `calc(${percent}% + ${backgroundOffset}px) 100%`
  const unitLeftPosition = `calc(${percent}% - ${dynamicOffset}px)`

  return {
    sliderBackgroundSize,
    unitLeftPosition
  }
}
