import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'preview',
    'previewWrapper',
    'submitButton',
    'titleEnabled',
    'title',
    'introductionTextEnabled',
    'introductionText',
    'mainImageImageBlobEnabled',
    'mainImageImageBlob',
    'bookDirectEnabled',
    'bookDirectTitle',
    'bookDirectDescription',
    'bookDirectLink',
    'bookDirectPromoCodeEnabled',
    'bookDirectPromoCodeCode',
    'bookDirectPromoCodeText',
    'pmsFeaturedPropertiesEnabled',
    'pmsFeaturedPropertiesTitle',
    'pmsFeaturedPropertiesNumberOfProperties',
    'pmsFeaturedPropertiesPropertyOneName',
    'pmsFeaturedPropertiesPropertyOneDescription',
    'pmsFeaturedPropertiesPropertyOneFeat1',
    'pmsFeaturedPropertiesPropertyOneFeat2',
    'pmsFeaturedPropertiesPropertyOneFeat3',
    'pmsFeaturedPropertiesPropertyOneImage',
    'pmsFeaturedPropertiesPropertyOneDateRange',
    'pmsFeaturedPropertiesPropertyOnePricePerNight',
    'pmsFeaturedPropertiesPropertyOneLink',
    'pmsFeaturedPropertiesPropertyTwoName',
    'pmsFeaturedPropertiesPropertyTwoDescription',
    'pmsFeaturedPropertiesPropertyTwoFeat1',
    'pmsFeaturedPropertiesPropertyTwoFeat2',
    'pmsFeaturedPropertiesPropertyTwoFeat3',
    'pmsFeaturedPropertiesPropertyTwoImage',
    'pmsFeaturedPropertiesPropertyTwoDateRange',
    'pmsFeaturedPropertiesPropertyTwoPricePerNight',
    'pmsFeaturedPropertiesPropertyTwoLink',
    'pmsFeaturedPropertiesPropertyThreeName',
    'pmsFeaturedPropertiesPropertyThreeDescription',
    'pmsFeaturedPropertiesPropertyThreeFeat1',
    'pmsFeaturedPropertiesPropertyThreeFeat2',
    'pmsFeaturedPropertiesPropertyThreeFeat3',
    'pmsFeaturedPropertiesPropertyThreeImage',
    'pmsFeaturedPropertiesPropertyThreeDateRange',
    'pmsFeaturedPropertiesPropertyThreePricePerNight',
    'pmsFeaturedPropertiesPropertyThreeLink',
    'pmsFeaturedPropertiesPropertyFourName',
    'pmsFeaturedPropertiesPropertyFourDescription',
    'pmsFeaturedPropertiesPropertyFourFeat1',
    'pmsFeaturedPropertiesPropertyFourFeat2',
    'pmsFeaturedPropertiesPropertyFourFeat3',
    'pmsFeaturedPropertiesPropertyFourImage',
    'pmsFeaturedPropertiesPropertyFourDateRange',
    'pmsFeaturedPropertiesPropertyFourPricePerNight',
    'pmsFeaturedPropertiesPropertyFourLink',
    'closingTextEnabled',
    'closingText',
    'socialLinksEnabled',
    'socialLinksFacebookEnabled',
    'socialLinksInstagramEnabled',
    'socialLinksTiktokEnabled',
    'socialLinksYoutubeEnabled',
    'socialLinksLinkedinEnabled'
  ]

  initialize() {
    this.debouncedRefreshPreview = _.debounce(() => {
      this.refreshPreview()
    }, 350)

    this.boundFormSubmitEvent = this.formSubmitEvent.bind(this)
    this.boundFormSubmitEnd = this.formSubmitEnd.bind(this)
    document.addEventListener('turbo:submit-start',  this.boundFormSubmitEvent)
    document.addEventListener('turbo:submit-end', this.boundFormSubmitEnd)
  }

  fillPMSFeaturedPropertiesData(e) {
    const numbersToWords = { 1: 'One', 2: 'Two', 3: 'Three', 4: 'Four' }

    e.detail.properties.forEach((property, index) => {
      this.setPropertyData(property, numbersToWords[index + 1])
    })

    this.onChange()
  }
  
  setPropertyData(property, propertyIndex) {
    this[`pmsFeaturedPropertiesProperty${propertyIndex}NameTarget`].value = property.pms_property_name;
    this[`pmsFeaturedPropertiesProperty${propertyIndex}DescriptionTarget`].value = property.pms_property_details.description;
    this[`pmsFeaturedPropertiesProperty${propertyIndex}Feat1Target`].value = property.pms_property_details.bedrooms;
    this[`pmsFeaturedPropertiesProperty${propertyIndex}Feat2Target`].value = property.pms_property_details.bathrooms;
    this[`pmsFeaturedPropertiesProperty${propertyIndex}Feat3Target`].value = property.pms_property_details.max_guests;
    this[`pmsFeaturedPropertiesProperty${propertyIndex}DateRangeTarget`].value = property.availability.date_range;
    this[`pmsFeaturedPropertiesProperty${propertyIndex}PricePerNightTarget`].value = property.availability.average_rate_value_with_currency;
    this[`pmsFeaturedPropertiesProperty${propertyIndex}LinkTarget`].value = property.pms_property_details.direct_booking_website_listing_url;


    if (property.pms_property_image_blob) {
      // Decode the image blob and set it as the image
      const byteString = atob(property.pms_property_image_blob.data);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([uint8Array], { type: property.pms_property_image_blob.content_type });
      const file = new File([blob], property.pms_property_image_blob.file_name);
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);

      const imageInput = document.getElementById(`email_marketing_upcoming_availability_email_creation_pms_featured_properties_attributes_property_${propertyIndex.toLowerCase()}_image`);
      imageInput.files = dataTransfer.files;
      imageInput.dispatchEvent(new Event('change', { bubbles: true }));
    }
  }

  formSubmitEvent(event) {
    if($(event.target).data('target') === 'upcomingAvailabilityEmailForm') {
      this.submitButtonTarget.innerHTML = '<div class="mx-2.5 spinner spinner--button"></div>'
    }
  }

  formSubmitEnd(event) {
    if($(event.target).data('target') === 'upcomingAvailabilityEmailForm') {
      if (event.detail.success) {
        this.submitButtonTarget.disabled = true
        this.interval = setInterval(() => { this.poll() }, 1500)
      }
    }
  }

  connect() {
    $(this.previewTargets[0]).addClass('ondeck-preview')

    $(this.previewTargets).on('load', (e) => {
      if ($(e.target).attr('src')) {
        this.previewTargets.forEach((el, i) => {
          $(el).toggleClass('ondeck-preview')
        })
      }
    })

    this.onChange()
  }

  disconnect() {
    document.removeEventListener('turbo:submit-start', this.boundFormSubmitEvent)
    document.removeEventListener('turbo:submit-end', this.boundFormSubmitEnd)

    clearInterval(this.interval)
  }

  onChange() {
    this.debouncedRefreshPreview()
  }

  refreshPreview() {
    const src = `${this.data.get('preview-path')}?${$.param(this.previewParams)}`
    $(this.previewTargets).filter('.ondeck-preview').attr('src', src)
    this.adjustIframeHeight()
  }

  adjustIframeHeight() {
    this.previewTargets.forEach(iframe => {
      iframe.onload = () => {
        iframe.style.height = (iframe.contentWindow.document.body.scrollHeight + 140) + 'px'
      }
    })
  }

  get previewParams() {
    const params = {
      title: {
        title: this.titleTarget.value,
        enabled: this.titleEnabledTarget.checked
      },
      introduction_text: {
        introduction_text: this.introductionTextTarget.value,
        enabled: this.introductionTextEnabledTarget.checked
      },
      main_image: {
        url: this.mainImageImageBlobTarget.value,
        enabled: this.mainImageImageBlobEnabledTarget.checked
      },
      book_direct: {
        title: this.bookDirectTitleTarget.value,
        description: this.bookDirectDescriptionTarget.value,
        link: this.bookDirectLinkTarget.value,
        promo_code_enabled: this.bookDirectPromoCodeEnabledTarget.checked,
        promo_code_code: this.bookDirectPromoCodeCodeTarget.value,
        promo_code_text: this.bookDirectPromoCodeTextTarget.value,
        enabled: this.bookDirectEnabledTarget.checked,
      },
      featured_properties: {
        title: this.pmsFeaturedPropertiesTitleTarget.value,
        number_of_properties: this.pmsFeaturedPropertiesNumberOfPropertiesTarget.value,
        property_one_name: this.pmsFeaturedPropertiesPropertyOneNameTarget.value,
        property_one_description: this.pmsFeaturedPropertiesPropertyOneDescriptionTarget.value,
        property_one_image: this.pmsFeaturedPropertiesPropertyOneImageTarget.value,
        property_one_feat1: this.pmsFeaturedPropertiesPropertyOneFeat1Target.value,
        property_one_feat2: this.pmsFeaturedPropertiesPropertyOneFeat2Target.value,
        property_one_feat3: this.pmsFeaturedPropertiesPropertyOneFeat3Target.value,
        property_one_date_range: this.pmsFeaturedPropertiesPropertyOneDateRangeTarget.value,
        property_one_price_per_night: this.pmsFeaturedPropertiesPropertyOnePricePerNightTarget.value,
        property_one_link: this.pmsFeaturedPropertiesPropertyOneLinkTarget.value,
        property_two_name: this.pmsFeaturedPropertiesPropertyTwoNameTarget.value,
        property_two_description: this.pmsFeaturedPropertiesPropertyTwoDescriptionTarget.value,
        property_two_image: this.pmsFeaturedPropertiesPropertyTwoImageTarget.value,
        property_two_feat1: this.pmsFeaturedPropertiesPropertyTwoFeat1Target.value,
        property_two_feat2: this.pmsFeaturedPropertiesPropertyTwoFeat2Target.value,
        property_two_feat3: this.pmsFeaturedPropertiesPropertyTwoFeat3Target.value,
        property_two_date_range: this.pmsFeaturedPropertiesPropertyTwoDateRangeTarget.value,
        property_two_price_per_night: this.pmsFeaturedPropertiesPropertyTwoPricePerNightTarget.value,
        property_two_link: this.pmsFeaturedPropertiesPropertyTwoLinkTarget.value,
        property_three_name: this.pmsFeaturedPropertiesPropertyThreeNameTarget.value,
        property_three_description: this.pmsFeaturedPropertiesPropertyThreeDescriptionTarget.value,
        property_three_image: this.pmsFeaturedPropertiesPropertyThreeImageTarget.value,
        property_three_feat1: this.pmsFeaturedPropertiesPropertyThreeFeat1Target.value,
        property_three_feat2: this.pmsFeaturedPropertiesPropertyThreeFeat2Target.value,
        property_three_feat3: this.pmsFeaturedPropertiesPropertyThreeFeat3Target.value,
        property_three_date_range: this.pmsFeaturedPropertiesPropertyThreeDateRangeTarget.value,
        property_three_price_per_night: this.pmsFeaturedPropertiesPropertyThreePricePerNightTarget.value,
        property_three_link: this.pmsFeaturedPropertiesPropertyThreeLinkTarget.value,
        property_four_name: this.pmsFeaturedPropertiesPropertyFourNameTarget.value,
        property_four_description: this.pmsFeaturedPropertiesPropertyFourDescriptionTarget.value,
        property_four_image: this.pmsFeaturedPropertiesPropertyFourImageTarget.value,
        property_four_feat1: this.pmsFeaturedPropertiesPropertyFourFeat1Target.value,
        property_four_feat2: this.pmsFeaturedPropertiesPropertyFourFeat2Target.value,
        property_four_feat3: this.pmsFeaturedPropertiesPropertyFourFeat3Target.value,
        property_four_date_range: this.pmsFeaturedPropertiesPropertyFourDateRangeTarget.value,
        property_four_price_per_night: this.pmsFeaturedPropertiesPropertyFourPricePerNightTarget.value,
        property_four_link: this.pmsFeaturedPropertiesPropertyFourLinkTarget.value,
        enabled: this.pmsFeaturedPropertiesEnabledTarget.checked
      },
      closing_text: {
        closing_text: this.closingTextTarget.value,
        enabled: this.closingTextEnabledTarget.checked
      },
    };

    if (this.hasSocialLinksEnabledTarget) {
      params.social_links = {
        enabled: this.socialLinksEnabledTarget.checked,
        facebook_enabled: (this.hasSocialLinksFacebookEnabledTarget ? this.socialLinksFacebookEnabledTarget.checked : false),
        instagram_enabled: (this.hasSocialLinksInstagramEnabledTarget ? this.socialLinksInstagramEnabledTarget.checked : false),
        tiktok_enabled: (this.hasSocialLinksTiktokEnabledTarget ? this.socialLinksTiktokEnabledTarget.checked : false),
        youtube_enabled: (this.hasSocialLinksYoutubeEnabledTarget ? this.socialLinksYoutubeEnabledTarget.checked : false),
        linkedin_enabled: (this.hasSocialLinksLinkedinEnabledTarget ? this.socialLinksLinkedinEnabledTarget.checked : false)
      }
    }

    return params;
  }

  poll() {
    const url = this.data.get('status-check-path')
    const setupDeliveryEnabled = this.data.get('setup-delivery-enabled')
    const setupDeliveryRedirectionUrl = this.data.get('setup-delivery-redirect-path');
    const campaignMonitorRedirectionUrl = this.data.get('campaign-monitor-redirect-path');

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.campaign_monitor_campaign_id) {
          clearInterval(this.interval)
          if (setupDeliveryEnabled === 'true') {
            window.location.href = setupDeliveryRedirectionUrl
          } else {
            window.location.href = campaignMonitorRedirectionUrl+data.campaign_monitor_campaign_id
          }
        }
      })
  }
}