import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'subscriberCount',
    'price',
    'unit',
    'subscriberCountIndexInput',
    'currency',
    'discountedPrice',
    'discountedCurrency',
    'discountedUnit'
  ]

  static THUMB_WIDTH = 26


  // CLEANUP: EM PRICING 2025 CHANGES
  // Delete this controller after 2025 begins

  static SUBSCRIBER_COUNT_INFO = [
    { currency: '$', formattedPrice: '15', discountedPrice: 'FREE', contactText: '1 &ndash; 200', unit: '/month' },
    { currency: '$', formattedPrice: '22.50', discountedPrice: '7.50', contactText: '201 &ndash; 500', unit: '/month' },
    { currency: '$', formattedPrice: '30', discountedPrice: '15', contactText: '501 &ndash; 750', unit: '/month' },
    { currency: '$', formattedPrice: '35', discountedPrice: '20', contactText: '751 &ndash; 1,000', unit: '/month' },
    { currency: '$', formattedPrice: '45', discountedPrice: '30', contactText: '1,001 &ndash; 1,500', unit: '/month' },
    { currency: '$', formattedPrice: '65', discountedPrice: '50', contactText: '1,501 &ndash; 2,500', unit: '/month' },
    { currency: '$', formattedPrice: '85', discountedPrice: '70', contactText: '2,501 &ndash; 5,000', unit: '/month' },
    { currency: '$', formattedPrice: '105', discountedPrice: '90', contactText: '5,001 &ndash; 7,500', unit: '/month' },
    { currency: '$', formattedPrice: '130', discountedPrice: '115', contactText: '7,501 &ndash; 10,000', unit: '/month' },
    { currency: '$', formattedPrice: '160', discountedPrice: '145', contactText: '10,001 &ndash; 15,000', unit: '/month' },
    { currency: '$', formattedPrice: '190', discountedPrice: '175', contactText: '15,001 &ndash; 20,000', unit: '/month' },
    { currency: '$', formattedPrice: '250', discountedPrice: '235', contactText: '20,001 &ndash; 25,000', unit: '/month' },
    { currency: '$', formattedPrice: '300', discountedPrice: '285', contactText: '25,001 &ndash; 30,000', unit: '/month' },
    { currency: '$', formattedPrice: '350', discountedPrice: '335', contactText: '30,001 &ndash; 40,000', unit: '/month' },
    { currency: '$', formattedPrice: '400', discountedPrice: '385', contactText: '40,001 &ndash; 50,000', unit: '/month' },
    { currency: '$', formattedPrice: '515', discountedPrice: '500', contactText: '50,001 &ndash; 75,000', unit: '/month' },
    { currency: '$', formattedPrice: '765', discountedPrice: '750', contactText: '75,001 &ndash; 100,000', unit: '/month' },
    { currency: '$', formattedPrice: '965', discountedPrice: '950', contactText: '100,001 &ndash; 130,000', unit: '/month' },
    { currency: '$', formattedPrice: '1,115', discountedPrice: '1,100', contactText: '130,001 &ndash; 150,000', unit: '/month' },
    { currency: '$', formattedPrice: '1,415', discountedPrice: '1,400', contactText: '150,001 &ndash; 200,000', unit: '/month' },
    { currency: '', formattedPrice: 'Custom', discountedPrice: 'Custom', contactText: '200,001+', unit: '' }
  ]

  connect() {
    this.updateSubscriberCount()
  }

  updateSubscriberCount() {
    const value = this.subscriberCountIndexInputTarget.value
    const min = this.subscriberCountIndexInputTarget.min
    const max = this.subscriberCountIndexInputTarget.max
    const info = this.constructor.SUBSCRIBER_COUNT_INFO[value]
    const percent = (value - min) * 100 / (max - min)
    const thumbOffset = this.constructor.THUMB_WIDTH / 100 * percent
    const backgroundOffset = this.constructor.THUMB_WIDTH / 2 - thumbOffset

    this.subscriberCountIndexInputTarget.style.backgroundSize = `calc(${percent}% + ${backgroundOffset}px) 100%`
    $(this.subscriberCountTargets).html(info.contactText)
    $(this.priceTargets).html(info.formattedPrice)
    $(this.unitTargets).text(info.unit)
    $(this.currencyTargets).text(info.currency)
    $(this.discountedPriceTargets).html(info.discountedPrice)

    if (info.discountedPrice === 'FREE') {
      $(this.discountedCurrencyTargets).html('')
      $(this.discountedUnitTargets).html('')
    } else {
      $(this.discountedCurrencyTargets).html(info.currency)
      $(this.discountedUnitTargets).html(info.unit)
    }
  }
}
