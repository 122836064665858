export const CURRENT_SUBSCRIBER_COUNT_INFO = [
  { currency: '$', formattedPrice: '0', discountedPrice: 'FREE', contactText: '0', unit: '/month' },
  { currency: '$', formattedPrice: '15', discountedPrice: 'FREE', contactText: '200', unit: '/month' },
  { currency: '$', formattedPrice: '22.50', discountedPrice: '7.50', contactText: '500', unit: '/month' },
  { currency: '$', formattedPrice: '30', discountedPrice: '15', contactText: '750', unit: '/month' },
  { currency: '$', formattedPrice: '35', discountedPrice: '20', contactText: '1,000', unit: '/month' },
  { currency: '$', formattedPrice: '45', discountedPrice: '30', contactText: '1,500', unit: '/month' },
  { currency: '$', formattedPrice: '65', discountedPrice: '50', contactText: '2,500', unit: '/month' },
  { currency: '$', formattedPrice: '85', discountedPrice: '70', contactText: '5,000', unit: '/month' },
  { currency: '$', formattedPrice: '105', discountedPrice: '90', contactText: '7,500', unit: '/month' },
  { currency: '$', formattedPrice: '130', discountedPrice: '115', contactText: '10,000', unit: '/month' },
  { currency: '$', formattedPrice: '160', discountedPrice: '145', contactText: '15,000', unit: '/month' },
  { currency: '$', formattedPrice: '190', discountedPrice: '175', contactText: '20,000', unit: '/month' },
  { currency: '$', formattedPrice: '250', discountedPrice: '235', contactText: '25,000', unit: '/month' },
  { currency: '$', formattedPrice: '300', discountedPrice: '285', contactText: '30,000', unit: '/month' },
  { currency: '$', formattedPrice: '350', discountedPrice: '335', contactText: '40,000', unit: '/month' },
  { currency: '$', formattedPrice: '400', discountedPrice: '385', contactText: '50,000', unit: '/month' },
  { currency: '$', formattedPrice: '515', discountedPrice: '500', contactText: '75,000', unit: '/month' },
  { currency: '$', formattedPrice: '765', discountedPrice: '750', contactText: '100,000', unit: '/month' },
  { currency: '$', formattedPrice: '965', discountedPrice: '950', contactText: '130,000', unit: '/month' },
  { currency: '$', formattedPrice: '1,115', discountedPrice: '1,100', contactText: '150,000', unit: '/month' },
  { currency: '$', formattedPrice: '1,415', discountedPrice: '1,400', contactText: '200,000', unit: '/month' }
]

export const CURRENT_WIFI_SUBSCRIPTION_PRICE_INFO = [
  { currency: '$', price: 0, first: 0, last: 0, unit: '/month', custom: false },
  { currency: '$', price: 15, first: 1, last: 1, unit: '/month', custom: false },
  { currency: '$', price: 12, first: 2, last: 4, unit: '/month', custom: false },
  { currency: '$', price: 10, first: 5, last: 9, unit: '/month', custom: false },
  { currency: '$', price: 9.5, first: 10, last: 14, unit: '/month', custom: false },
  { currency: '$', price: 9, first: 15, last: 19, unit: '/month', custom: false },
  { currency: '$', price: 8.5, first: 20, last: 24, unit: '/month', custom: false },
  { currency: '$', price: 8, first: 25, last: 29, unit: '/month', custom: false },
  { currency: '$', price: 7.5, first: 30, last: 49, unit: '/month', custom: false },
  { currency: '$', price: 7, first: 50, last: 74, unit: '/month', custom: false },
  { currency: '$', price: 6.5, first: 75, last: 99, unit: '/month', custom: false },
  { currency: '$', price: 6, first: 100, last: 149, unit: '/month', custom: false },
  { currency: '$', price: 5.5, first: 150, last: 199, unit: '/month', custom: false },
  { currency: '$', price: 5, first: 200, last: 299, unit: '/month', custom: false },
  { currency: '$', price: 4.5, first: 300, last: 499, unit: '/month', custom: false },
  { currency: '$', price: 4, first: 500, last: 999, unit: '/month', custom: false }
]

export const NEXT_YEAR_SUBSCRIBER_COUNT_INFO = [
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '0',
      discountedPrice: 'FREE'
    },
    plusPlan: {
      formattedPrice: '0',
      discountedPrice: 'FREE'
    },
    contactText: '0',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 0,
      upperLimit: 0,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '15',
      discountedPrice: 'FREE'
    },
    plusPlan: {
      formattedPrice: '20',
      discountedPrice: 'FREE'
    },
    contactText: '200',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 1,
      upperLimit: 200,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '22.50',
      discountedPrice: '7.50'
    },
    plusPlan: {
      formattedPrice: '30',
      discountedPrice: '10'
    },
    contactText: '350',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 201,
      upperLimit: 350,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '25',
      discountedPrice: '10',
    },
    plusPlan: {
      formattedPrice: '35',
      discountedPrice: '15',
    },
    contactText: '500',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 351,
      upperLimit: 500,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '30',
      discountedPrice: '15',
    },
    plusPlan: {
      formattedPrice: '40',
      discountedPrice: '20',
    },
    contactText: '750',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 501,
      upperLimit: 750,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '35',
      discountedPrice: '20',
    },
    plusPlan: {
      formattedPrice: '45',
      discountedPrice: '25',
    },
    contactText: '1,000',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 751,
      upperLimit: 1000,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '45',
      discountedPrice: '30',
    },
    plusPlan: {
      formattedPrice: '60',
      discountedPrice: '40',
    },
    contactText: '1,250',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 1001,
      upperLimit: 1250,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '55',
      discountedPrice: '40',
    },
    plusPlan: {
      formattedPrice: '75',
      discountedPrice: '55',
    },
    contactText: '1,500',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 1251,
      upperLimit: 1500,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '65',
      discountedPrice: '50',
    },
    plusPlan: {
      formattedPrice: '90',
      discountedPrice: '70',
    },
    contactText: '2,000',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 1501,
      upperLimit: 2000,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '75',
      discountedPrice: '60',
    },
    plusPlan: {
      formattedPrice: '100',
      discountedPrice: '80',
    },
    contactText: '2,500',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 2001,
      upperLimit: 2500,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '85',
      discountedPrice: '70',
    },
    plusPlan: {
      formattedPrice: '115',
      discountedPrice: '95',
    },
    contactText: '3,000',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 2501,
      upperLimit: 3000,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '95',
      discountedPrice: '80',
    },
    plusPlan: {
      formattedPrice: '130',
      discountedPrice: '110',
    },
    contactText: '4,000',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 3001,
      upperLimit: 4000,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '100',
      discountedPrice: '85',
    },
    plusPlan: {
      formattedPrice: '135',
      discountedPrice: '115',
    },
    contactText: '5,000',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 4001,
      upperLimit: 5000,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '105',
      discountedPrice: '90',
    },
    plusPlan: {
      formattedPrice: '140',
      discountedPrice: '120',
    },
    contactText: '6,000',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 5001,
      upperLimit: 6000,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '105',
      discountedPrice: '100',
    },
    plusPlan: {
      formattedPrice: '155',
      discountedPrice: '135',
    },
    contactText: '8,000',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 6001,
      upperLimit: 8000,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '130',
      discountedPrice: '115',
    },
    plusPlan: {
      formattedPrice: '175',
      discountedPrice: '155',
    },
    contactText: '10,000',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 8001,
      upperLimit: 10000,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '160',
      discountedPrice: '145',
    },
    plusPlan: {
      formattedPrice: '215',
      discountedPrice: '195',
    },
    contactText: '15,000',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 10001,
      upperLimit: 15000,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '205',
      discountedPrice: '190',
    },
    plusPlan: {
      formattedPrice: '275',
      discountedPrice: '255',
    },
    contactText: '20,000',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 15001,
      upperLimit: 20000,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '300',
      discountedPrice: '285',
    },
    plusPlan: {
      formattedPrice: '405',
      discountedPrice: '385',
    },
    contactText: '30,000',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 20001,
      upperLimit: 30000,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '350',
      discountedPrice: '335',
    },
    plusPlan: {
      formattedPrice: '470',
      discountedPrice: '450',
    },
    contactText: '40,000',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 30001,
      upperLimit: 40000,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '400',
      discountedPrice: '385',
    },
    plusPlan: {
      formattedPrice: '540',
      discountedPrice: '520',
    },
    contactText: '50,000',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 40001,
      upperLimit: 50000,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '590',
      discountedPrice: '575',
    },
    plusPlan: {
      formattedPrice: '795',
      discountedPrice: '775',
    },
    contactText: '75,000',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 50001,
      upperLimit: 75000,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '765',
      discountedPrice: '750',
    },
    plusPlan: {
      formattedPrice: '1,035',
      discountedPrice: '1,015',
    },
    contactText: '100,000',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 75001,
      upperLimit: 100000,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '965',
      discountedPrice: '950',
    },
    plusPlan: {
      formattedPrice: '1,305',
      discountedPrice: '1,285',
    },
    contactText: '130,000',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 100001,
      upperLimit: 130000,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '1,065',
      discountedPrice: '1,050',
    },
    plusPlan: {
      formattedPrice: '1,440',
      discountedPrice: '1,420',
    },
    contactText: '150,000',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 130001,
      upperLimit: 150000,
    }
  },
  {
    currency: '$',
    basicPlan: {
      formattedPrice: '1,415',
      discountedPrice: '1,400',
    },
    plusPlan: {
      formattedPrice: '1,910',
      discountedPrice: '1,890',
    },
    contactText: '1,000,000',
    unit: '/month',
    subscribersRange: {
      lowerLimit: 150001,
      upperLimit: 1000000,
    }
  },
]

export const EMAIL_MARKETING_ONBOARDING_NEXT_YEAR_SUBSCRIBER_COUNT_INFO = [
  ...NEXT_YEAR_SUBSCRIBER_COUNT_INFO.filter(info => info.contactText !== '0'),
  {
    currency: '',
    basicPlan: {
      formattedPrice: 'Custom',
      discountedPrice: 'Custom',
    },
    plusPlan: {
      formattedPrice: 'Custom',
      discountedPrice: 'Custom',
    },
    contactText: '1,000,000+',
    unit: '',
    subscribersRange: {
      lowerLimit: 1000001,
      upperLimit: '+inf',
    }
  }
]

export const NEXT_YEAR_WIFI_SUBSCRIPTION_PRICE_INFO = [
  { currency: '$', price: 0, first: 0, last: 0, unit: '/month', custom: false },
  { currency: '$', price: 19, first: 1, last: 1, unit: '/month', custom: false },
  { currency: '$', price: 14, first: 2, last: 2, unit: '/month', custom: false },
  { currency: '$', price: 13.5, first: 3, last: 4, unit: '/month', custom: false },
  { currency: '$', price: 11.5, first: 5, last: 9, unit: '/month', custom: false },
  { currency: '$', price: 11, first: 10, last: 14, unit: '/month', custom: false },
  { currency: '$', price: 10.5, first: 15, last: 19, unit: '/month', custom: false },
  { currency: '$', price: 9.5, first: 20, last: 24, unit: '/month', custom: false },
  { currency: '$', price: 9, first: 25, last: 29, unit: '/month', custom: false },
  { currency: '$', price: 8.5, first: 30, last: 49, unit: '/month', custom: false },
  { currency: '$', price: 8, first: 50, last: 74, unit: '/month', custom: false },
  { currency: '$', price: 7.5, first: 75, last: 99, unit: '/month', custom: false },
  { currency: '$', price: 7, first: 100, last: 149, unit: '/month', custom: false },
  { currency: '$', price: 6.5, first: 150, last: 199, unit: '/month', custom: false },
  { currency: '$', price: 6, first: 200, last: 249, unit: '/month', custom: false },
  { currency: '$', price: 5.5, first: 250, last: 299, unit: '/month', custom: false },
  { currency: '$', price: 5, first: 300, last: 399, unit: '/month', custom: false },
  { currency: '$', price: 4.75, first: 400, last: 499, unit: '/month', custom: false },
  { currency: '$', price: 4.5, first: 500, last: 999, unit: '/month', custom: false },
  { currency: '$', price: 4.25, first: 1000, last: 10000, unit: '/month', custom: false }
]
